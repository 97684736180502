import axiosInstance from 'services/interceptor';

export const getAllEvents = async (): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/guest/events`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getEventById = async (eventId: string, type='event'): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/guest/events/${eventId}?type=${type}`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getBoothInfo = async (eventId = '', boothId = ''): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/guest/booth/${boothId}?event=${eventId}`);
    return response.data;
  } catch (error: any) {
    throw error.response ? error.response.data : error.message;
  }
};

export const getExhibitionData = async (mapId: string) => {

  const params = {
      map_id: mapId,
  }
  try {
      const response = await axiosInstance.get(`/exhibitions`, { params }); // /guest
      if (response.data) {

      }
      return response.data;
  } catch (error: any) {
      console.log(error.message);
      // throw error;
      return [];
  }
};



