// src/components/CompanyInfo.tsx
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setBookingData } from '../../store/bookingSlice';

import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import { addCompany, getCompanyByEmail } from 'services/company';
import { dA } from '@fullcalendar/core/internal-common';
import { toast } from "react-toastify";
import { checkValidPayment } from 'services/payments';

const CompanyInfo: React.FC<{ event: any, booth: any, nextStep: () => void }> = ({ event, booth, nextStep }) => {
    const data = useSelector((state: RootState) => state.booking.data);
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setBookingData({ [e.target.name]: e.target.value }));
    };

    const handleChangeEmail = async (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setBookingData({ [e.target.name]: e.target.value }));
        try {
            const resp = await getCompanyByEmail(e.target.value);
            const key_arr = ['name', 'first_name', 'last_name', 'phone', 'country', 'city', 'postcode', 'address']
            if (resp) {
                key_arr.map((item: any) => {
                    dispatch(setBookingData({ [item]: resp[item] }));
                })
            }

        } catch (err: any) {
            console.log(err.message);

        }
    };

    const handleCheckboxChange = (checked: any) => {
        setChecked(checked);
    };

    const handleBooking = async () => {
        // console.log(data);
        try {

            if (
                !data.primary_email ||
                !data.name ||
                !data.first_name ||
                !data.last_name ||
                !data.phone ||
                !data.country ||
                !data.city ||
                !data.postcode ||
                !data.address ||
                !checked
            ) {
                toast.error("Please input correct information");
                return;
            }

            const resp = await addCompany(data);
            const canPay = await checkValidPayment({
                primary_email: data.primary_email,
                event_id: event.id,
                booth_no: booth.no
            });
            
            if(canPay && canPay.status) {
                nextStep();
            } else {
                toast.info(canPay.message || "This booth has already been booked.");
            }
        } catch (err: any) {
            console.log('error', err.message);
            toast.error("Something went wrong, please try later");
        }

    }
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Comany information</h2>
            <div className="grid grid-cols-2 gap-2" >
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Email address
                    </span>
                    <Input
                        type="email"
                        placeholder="torch@events.com"
                        className="mt-1"
                        name="primary_email"
                        value={data.primary_email}
                        onChange={handleChangeEmail}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Company Name
                    </span>
                    <Input
                        type="text"
                        placeholder="Torch Events"
                        className="mt-1"
                        name={"name"}
                        value={data.name}
                        onChange={handleChange}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        First Name
                    </span>
                    <Input
                        type="text"
                        placeholder="John"
                        className="mt-1"
                        name={"first_name"}
                        onChange={handleChange}
                        value={data.first_name}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Last Name
                    </span>
                    <Input
                        type="text"
                        placeholder="Doe"
                        className="mt-1"
                        name='last_name'
                        value={data.last_name}
                        onChange={handleChange}
                    />
                </label>

                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Phone Number
                    </span>
                    <Input
                        type="text"
                        placeholder="+4412345677890"
                        className="mt-1"
                        name="phone"
                        value={data.phone}
                        onChange={handleChange}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Job Title
                    </span>
                    <Input
                        type="text"
                        placeholder="CEO"
                        className="mt-1"
                        name="job_title"
                        value={data.job_title}
                        onChange={handleChange}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Country
                    </span>
                    <Input
                        type="text"
                        placeholder="UK"
                        className="mt-1"
                        name="country"
                        value={data.country}
                        onChange={handleChange}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        City
                    </span>
                    <Input
                        type="text"
                        placeholder="City"
                        className="mt-1"
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Post code
                    </span>
                    <Input
                        type="text"
                        placeholder="Post code"
                        className="mt-1"
                        name="postcode"
                        value={data.postcode}
                        onChange={handleChange}
                    />
                </label>
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                        Address
                    </span>
                    <Input
                        type="text"
                        placeholder="Address"
                        className="mt-1"
                        name="address"
                        value={data.address}
                        onChange={handleChange}
                    />
                </label>

            </div>
            <div className="relative text-left my-4">
                <label className="flex items-center space-x-2 my-3">
                    <Checkbox
                        name="terms_and_conditions"
                        checked={checked}
                        onChange={handleCheckboxChange}
                    // inputProps={{ 'aria-label': 'Terms and Conditions Checkbox' }} // For accessibility
                    />
                    <span>
                        I agree to the{' '}
                        <a href={`/terms-conditions/${event.id}?type=event`} className="text-blue-500 hover:underline mx-1" target="_blank"
                            rel="noopener noreferrer">
                            Terms and Conditions
                            <i className="fas fa-external-link-alt inline-block ml-1" aria-label="Opens in a new window"></i>
                        </a>
                    </span>
                </label>
            </div>
            <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div>
            {/* <button onClick={nextStep}>Next</button> */}
            <div className="flex justify-end">
                {/* <ButtonPrimary type="button" onClick={nextStep}>Next</ButtonPrimary> */}
                <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleBooking}>
                    Proceed
                </button>
            </div>

        </div>
    );
};

export default CompanyInfo;
