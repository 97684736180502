// src/ExhibitionList.tsx

import React from 'react';
import { Applicant } from './types';

interface ExhibitionListProps {
  applicants: Applicant[];
}

const ExhibitionList: React.FC<ExhibitionListProps> = ({ applicants }) => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Exhibitor list</h1>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
        {applicants.map((applicant) => (
          <div
            key={applicant.id}
            className="flex bg-white rounded-lg shadow-md p-4 border border-gray-200"
          >
            {/* Image with fixed width and height */}
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/${applicant.company.logo}`}
              alt={`${applicant.company.name} logo`}
              className="w-24 h-24 object-contain rounded-md mr-4 flex-none"
            />

            {/* Content on the right */}
            <div className="flex flex-col flex-grow">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-lg font-semibold">{applicant.company.name}</h2>
                  <p className="text-sm text-gray-500">{applicant.company.description}</p>
                </div>
                <a
                  href={`https://${applicant.company.website_social_media}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline text-sm ml-4"
                >
                  {applicant.company.website_social_media}
                </a>
              </div>
              <div className="flex justify-end items-center mt-2">
                <p className="text-green-600 text-sm">
                  Booth #{applicant.booth}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExhibitionList;
