import { configureStore } from '@reduxjs/toolkit'
import userSlice from "./userSlice";
import coachSlice from "./coachSlice";
import playerSlice from "./playerSlice";
import bookingSlice from './bookingSlice';

export const store = configureStore({
    reducer: {
        user: userSlice,
        coach: coachSlice,
        player: playerSlice,
        booking: bookingSlice,
    },
    // Disable serializable check for localStorage data
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

// Subscribe to store changes and save data to localStorage
store.subscribe(() => {
    const state = store.getState();
    const userState = state.user;
    localStorage.setItem("user", userState.user);
    localStorage.setItem("player-coach", userState.playerCoach);
    localStorage.setItem("user-role", userState.userRole);
    localStorage.setItem("access-token", userState.accessToken);
    localStorage.setItem("refresh-token", userState.refreshToken);
    localStorage.setItem("user-info", JSON.stringify(userState.userInformation));
    // localStorage.setItem('locations', JSON.stringify(state.locations)); // Save locations data to localStorage
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch