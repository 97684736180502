import React, { FC, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { getEventById } from "services/events";

interface TermsConditionsPageProps {
  className?: string;
}

const TermsConditionsPage: FC<TermsConditionsPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const { id }: any = useParams(); // event_id
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type') || 'unknown'; // or map

  const [exhibitionData, setExhibitionData]: any = useState({
    event: {},
    map: { image: 'uploads/images/noimage.png' },
    applicants: [],
    shapes: [],
  });

  useEffect(() => {
    getEventById(id || '', type)
      .then((res) => {
        setExhibitionData({
          ...res,
        });
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [id, type]);

  const convertNewLinesToBreaks = (text: string) => {
    return text.replace(/\n/g, '<br />');
  };

  return (
    <div className={`nc-TermsConditionsPage ${className} bg-gray-50 min-h-screen`}>
      <main className="container mx-auto p-6 lg:py-12">
        <header className="mb-8 text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-800">
            Terms and Conditions
          </h1>
          <p className="mt-2 text-gray-500">
            Please read the terms and conditions carefully before participating in the event.
          </p>
        </header>
        <section className="bg-white rounded-lg shadow p-6 lg:p-10">
          <div className="prose max-w-none">
            {exhibitionData.event?.terms_and_conditions ? (
              <div
                dangerouslySetInnerHTML={{ __html: convertNewLinesToBreaks(exhibitionData.event.terms_and_conditions) }}
              />
            ) : (
              <p className="text-gray-600">
                No terms and conditions available for this event.
              </p>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default TermsConditionsPage;
