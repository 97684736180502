import axiosInstance from 'services/interceptor';

export const getInvoice = async (data: any): Promise<any> => {
    try {
        const response = await axiosInstance.get(`/guest/invoice`, {
            params: {
                event_id: data.event_id,
                booth_no: data.booth_no
            },
            responseType: 'blob', 
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}