
import axiosInstance from 'services/interceptor';

export const getCompanyByEmail = async (email: string): Promise<any> => {
    try {
        const response = await axiosInstance.get(`/guest/company`, {
            params: { email: email } // Pass email as a query parameter
        });
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}

export const addCompany = async (data: any): Promise<any> => {
    try {
        const response = await axiosInstance.post(`/guest/company`, data);
        return response.data;
    } catch (error: any) {
        throw error.response ? error.response.data : error.message;
    }
}