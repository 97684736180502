import axios, { AxiosResponse } from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Function to handle 401 unauthorized errors by redirecting to the login page
const handleUnauthorizedError = () => {
    // const navigate = useNavigate();
    localStorage.setItem("user", '');
    localStorage.setItem("player-coach", '');
    localStorage.setItem("user-role", '');
    localStorage.setItem("access-token", '');
    localStorage.setItem("refresh-token", '');
    localStorage.setItem("user-info", '');
    window.location.href = "/login";
};


// Axios request interceptor to add authentication token to requests
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('access-token'); // Assuming you store the access token in localStorage
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Axios response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
        const { status } = error.response || {};
        if (status === 401) {
            handleUnauthorizedError(); // Redirect to login page for unauthorized requests
        }
        return Promise.reject(error);
    }
);


export default axiosInstance;