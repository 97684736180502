import React, { FC, useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import SectionPlayer from "./SectionPlayer";
import SectionCoach from "./SectionCoach";
import { BACKGROUND_IMGS } from "data/data";

export interface AboutPageProps {
  className?: string;
}

const NUM_IMAGES = 22; // Total number of images

const AboutPage: FC<AboutPageProps> = ({ className = "" }) => {
  const [randomImage, setRandomImage] = useState<string>("");
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [inView, setInView] = useState<boolean>(false);

  const generateRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * NUM_IMAGES); // Random index from 0 to 21
    setRandomImage(BACKGROUND_IMGS[randomIndex]);
  };

  useEffect(() => {
    generateRandomImage();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3, // Delay each section by 0.3s
        duration: 0.5,
      },
    }),
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setInView(true);
          observer.disconnect(); // Stop observing after the first intersection
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [sectionRef]);

  return (
    <div
      className={`nc-AboutPage relative ${className}`}
      id="about"
      ref={sectionRef}
    >
      <div
        id="hero-image"
        className="absolute inset-0 w-full bg-cover bg-no-repeat bg-center"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1) , rgba(255, 255, 255, 0.9) 100%), url(${randomImage})`,
        }}
      ></div>
      <div className="relative container mx-auto py-20">
        {inView && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            custom={0}
            className="text-left"
          >
            <h1 className="font-semibold text-4xl md:text-5xl mb-10">About Us</h1>
            <div className="text-xl space-y-5">
              <p>
                Torch Events is your trusted partner in creating and managing unforgettable events. We believe that events are more than just gatherings—they are opportunities to inspire, connect, and make lasting memories. Whether you're hosting a small meetup or a large conference, our platform is designed to streamline every aspect of event planning.
              </p>
              <p>
                Our mission is to make event management accessible and efficient for everyone. Torch Events empowers organizers with the tools they need to manage registrations, promote their events, and engage with attendees effortlessly. From event creation to execution, we’re here to support your vision and help bring it to life.
              </p>
              <p>
                Our platform is built to be intuitive and flexible, so you can focus on delivering an exceptional experience for your attendees. We understand that every event is unique, and our customizable features ensure that your event stands out. Whether you're an experienced planner or new to event management, Torch Events is here to light the way.
              </p>
            </div>

          </motion.div>
        )}
      </div>

      {/* <div className="container mx-auto py-20">
        <h2 className="font-semibold text-3xl md:text-4xl mb-10">Meet Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <div className="flex flex-col items-center text-center">
            <img
              className="rounded-full w-32 h-32 mb-4"
              src="path-to-team-member-image"
              alt="Team Member"
            />
            <h3 className="text-xl font-semibold">John Doe</h3>
            <p className="text-gray-600">Founder & CEO</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img
              className="rounded-full w-32 h-32 mb-4"
              src="path-to-team-member-image"
              alt="Team Member"
            />
            <h3 className="text-xl font-semibold">Jane Smith</h3>
            <p className="text-gray-600">Head Coach</p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img
              className="rounded-full w-32 h-32 mb-4"
              src="path-to-team-member-image"
              alt="Team Member"
            />
            <h3 className="text-xl font-semibold">Sarah Brown</h3>
            <p className="text-gray-600">Chief Technology Officer</p>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default AboutPage;
