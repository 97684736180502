// src/components/StripePaymentPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getPaymentIntent, confirmPayment } from 'services/payments';
const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || 'your stripe api key'
const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

// Styling for the Card Element with Tailwind CSS
const cardStyle: any = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

// Form component for Stripe Payment
const CheckoutForm: React.FC<any> = ({ data }) => {
  const navigate = useNavigate();
  // console.log('payment data', data);

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  // Fetch client secret from the backend when component mounts
  
  useEffect(() => {
    getPaymentIntent({
      ...data,
      amount: data.booth_cost * 100, // amount in cents
      currency: 'gbp',
    })
      .then((response: any) => {
        setClientSecret(response.clientSecret);
      })
      .catch((error: any) => {
        setError(`${error.message}`);
      });
  }, []);

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    setLoading(true);
    setError(null);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) return;

    // Confirm the payment with the client secret from the backend
    const { error, paymentIntent }: any = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${data.name}`,
          email: data.primary_email,
        },
      },
    });

    // Check for errors and prepare the data to send to the server
    let paymentResult = {};
    if (error) {
      // Handle error scenario
      paymentResult = {
        success: false,
        message: error.message,
      };
      setError(error.message);
      setLoading(false);

    } else if (paymentIntent && paymentIntent.status === 'succeeded') {

      setSuccessMessage('Payment successful! Thank you for your purchase.');
      setLoading(false);

      // Handle success scenario
      paymentResult = {
        success: true,
        paymentIntentId: paymentIntent.id,
        amount: paymentIntent.amount,
        currency: paymentIntent.currency,
      };
      const resp = await confirmPayment({
        success: true,
        paymentIntentId: paymentIntent.id,
        amount: paymentIntent.amount,
        currency: paymentIntent.currency,
        clientSecret: clientSecret
      });

      // navigate(`/invoice?event=${data.event_id}&booth=${data.booth_no}`);
      const eventUrl = `/invoice?event=${data.event_id}&booth=${data.booth_no}`;
      window.open(eventUrl, '_blank');

    } else {
      // Handle other statuses
      paymentResult = {
        success: false,
        message: 'Payment was not successful',
      };
      setError('Something went wrong');
      setLoading(false);
    }

  };

  // Handle card input changes
  const handleCardChange = (event: any) => {
    setError(event.error ? event.error.message : '');
  };

  return (
    <form onSubmit={handleSubmit} className="w-100 mx-auto p-8 bg-white rounded-lg shadow-lg space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Purchase by Stripe</h2>

      {/* Card Element for Stripe */}
      <div className="border p-4 rounded-md shadow-sm">
        <CardElement options={cardStyle} onChange={handleCardChange} />
      </div>
      
      {/* Display Errors */}
      {error && <div className="text-red-500 text-xl mt-2">{error}</div>}

      {/* Payment Button */}
      <button
        type="submit"
        disabled={!stripe || loading || !clientSecret}
        className={`w-full mt-4 px-4 py-2 font-semibold text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
      >
        {loading ? 'Processing...' : 'Pay Now'}
      </button>

      {/* Success Message */}
      {successMessage && <div className="text-green-500 text-sm mt-2">{successMessage}</div>}
    </form>
  );
};

// Stripe Elements Wrapper
const StripePayment: React.FC<any> = ({ data }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm data={data} />
    </Elements>
  );
};

export default StripePayment;
