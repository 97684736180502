import React from 'react';
import { PayPalScriptProvider, PayPalButtons, PayPalButtonsComponentProps, FUNDING } from "@paypal/react-paypal-js";
import { createPaymentPaypal, confirmPaymentPaypal } from "../../services/payments";


const PayPalPayment: React.FC<any> = ({ data }) => {

  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "paypal-client-id",
    currency: "GBP",
    intent: "capture",
  };

  const createOrder = async (orderData: any, actions: any) => {

    try {
      // const orderId = '32V21829KC0831919';
      // const details = {
      //   given_name : 'details.payer.name.given_name'
      // }
      // const res = await confirmPaymentPaypal({
      //   orderId: orderId,
      //   details
      // });
      // return;

      // const order =  await actions.order.create({
      //   purchase_units: [
      //     {
      //       amount: {
      //         value: data.booth_cost || '1.00', 
      //         currency_code: 'GBP' 
      //       }
      //     }
      //   ]
      // });

      // console.log('paypal order', order);

      const response = await createPaymentPaypal({
        ...data,
        amount: data.booth_cost, // amount 
        currency: 'gbp',
        primary_email: data.primary_email,
        orderId: 'unknown',
      })

      if (!response) {
        throw new Error('Failed to create order');
      }
      console.log('response', response);
      const order = response.order;

      // const orderData: CreateOrderResponse = response.order;
      // return orderData.id; // PayPal expects an order ID
      
      // Extract the approval URL from the PayPal response
      const approveLink = order?.links.find((link: any) => link.rel === 'approve');
      if (!approveLink) {
        throw new Error('Approval link not found');
      }
      
      // Redirect the user to the PayPal approval URL
      window.location.href = approveLink.href;
      
      return response;
      
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  };

  const onApprove: PayPalButtonsComponentProps['onApprove'] = async (data, actions) => {
    // orderId
    const orderId = data.orderID;
    if (actions.order) {
      return actions.order.capture().then(async (details: any) => {
        console.log('Transaction completed by ' + details.payer.name.given_name);
        const res = await confirmPaymentPaypal({
          orderId: orderId,
          details
        });

      });
    } else {
      throw new Error('Order capture failed');
    }
  };

  return (
    <div className="paypal">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={onApprove}
          fundingSource={FUNDING.PAYPAL} // Show only PayPal button
          style={{
            layout: 'vertical',
            shape: 'rect',
            label: 'paypal',
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PayPalPayment;
