import React, { FC, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CompanyInfo from './CompanyInfo';
import Payment from './Payment';
import EventDetails from './Summary';

import BackgroundImg from 'images/pred_back.jpg';
import { QUOTES } from 'data/data';
import { getBoothInfo } from "services/events";

export interface PageBookingProps {
  className?: string;
}

const PageBooking: FC<PageBookingProps> = ({ className = '' }) => {

  const [searchParams] = useSearchParams();
  const event_id = searchParams.get('event') || '';
  const booth_id = searchParams.get('booth') || '';

  const [step, setStep] = useState(1);
  const [event, setEvent]: any = useState({});
  const [booth, setBooth]: any = useState({});

  const [randomQuote, setRandomQuote] = useState<any>(null);

  useEffect(() => {
    const generateRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * QUOTES.length);
      setRandomQuote(QUOTES[randomIndex]);
    };
    generateRandomQuote();

    getBoothInfo(event_id, booth_id)
      .then((res) => {
        setEvent({
          ...res.event
        });
        setBooth(res.booth)
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  const handleNextStep = async (currentStep: number) => {
    setStep(currentStep + 1);
  }
  return (
    <div
      className={`nc-PageBooking min-h-[calc(100vh-5rem)] flex justify-center items-center ${className}`}
      data-nc-id="PageBooking"
    >
      <Helmet>
        <title>Booking || Torch Events</title>
      </Helmet>
      <div className="xs:mb-16 my-4 mx-4 xs:mx-8 sm:my-8 flex flex-col sm:flex-row shadow-lg rounded w-full sm:mx-0 sm:w-4/5 md:w-3/4 lg:w-2/3">
        {/* Left Side: Quote Section */}
        <div
          className="w-full sm:w-1/2 md:w-2/5 min-h-[200px] p-0 bg-cover text-white rounded-t sm:rounded-l sm:rounded-tr-none"
          // style={{ backgroundImage: `url(${BackgroundImg})` }}
        >
          <EventDetails
            event={event}
            booth={booth}
          />
          {/* {randomQuote && (
            <div className="text-xl w-full">
              <h1 className="mb-4">" {randomQuote.quote} "</h1>
              <p>- {randomQuote.author}</p>
            </div>
          )} */}
        </div>

        {/* Right Side: Form Section */}
        <div className="mx-auto w-full sm:w-1/2 md:w-3/5 space-y-6 py-4 px-8">
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-xl bg-white dark:text-neutral-400 dark:bg-neutral-900">
              Exhibit Space Booking Form
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          {/* Form Steps */}
          <div className="h-full">
            {step === 1 && <CompanyInfo event={event} booth={booth} nextStep={() => handleNextStep(1)} />}
            {step === 2 && <Payment event={event} booth={booth} prevStep={() => setStep(1)} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBooking;
