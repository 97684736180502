import React, { FC } from "react";
import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

export interface SectionFaqProps {
  className?: string;
}

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "How do I create an event?",
    answer: `1) Sign up or log in to your Torch Events account
2) Click on "Create Event" in the dashboard
3) Fill out the event details including title, date, time, and location
4) Add a description and any other relevant information
5) Publish the event to make it visible to attendees`,
  },
  {
    question: "How do attendees register for an event?",
    answer:
      "Attendees can browse the event listings, select an event they are interested in, and click 'Register' to sign up. They will need to provide their name, email, and payment information if the event is paid.",
  },
  {
    question: "What are the costs to host an event?",
    answer:
      "Torch Events charges a small fee for each ticket sold through the platform. The fee is automatically deducted from the ticket price, and the remaining balance is transferred to the host’s account.",
  },
  {
    question: "Can I customize the registration form?",
    answer:
      "Yes, event hosts can customize the registration form to collect specific information from attendees. This can include adding custom fields for questions like dietary preferences or T-shirt sizes.",
  },
  {
    question: "How do I promote my event?",
    answer: `1) Share your event link on social media platforms
2) Send email invitations to your contacts
3) Use Torch Events' built-in promotional tools to reach a wider audience`,
  },
  {
    question: "How can I manage attendee lists?",
    answer: `1) Log in to your Torch Events account
2) Navigate to your event dashboard
3) Click on "Attendees" to view and manage the list
4) You can export the list, send messages, or check-in attendees on the day of the event`,
  },
  {
    question: "What support is available if I need help?",
    answer:
      "Torch Events offers 24/7 customer support through live chat and email. You can also access our Help Center for tutorials and FAQs.",
  },
];

const SectionFaq: FC<SectionFaqProps> = ({ className = "py-8 lg:py-20" }) => {
  return (
    <div
      className={`nc-SectionFaq bg-[rgba(0,0,0,0.8)] relative ${className}`}
      id="faq"
    >
      <div className="container mx-auto flex flex-col lg:flex-row">
        <div className="relative w-full">
          <h1 className="text-center text-4xl md:text-5xl font-semibold text-white mb-10">
            FREQUENTLY ASKED QUESTIONS
          </h1>
          {faqData.map((item, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full mb-3 px-4 py-2 font-medium text-left bg-white dark:bg-slate-800 rounded-lg hover:bg-slate-100/80 dark:hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75">
                    <span>{item.question}</span>
                    {open ? (
                      <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                    ) : (
                      <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel
                    className="p-4 pt-3 text-sm text-white dark:text-slate-300 leading-6"
                    as="div"
                  >
                    {item.answer.split("\n").map((line, idx) => (
                      <div key={idx}>{line}</div>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionFaq;
